'use client';

import { Input, Select, Textarea } from '@components/inputs';
import { useForm, ValidationError } from '@formspree/react';
import { Button } from '@ngg/components';
import type { FormFragment } from '@server/gql/graphql';
import { useMemo } from 'react';
import type { Dictionary } from 'types/common';

export default function Contact({
    dictionary,
    reference,
}: FormFragment & {
    dictionary: Dictionary;
}) {
    const [state, handleSubmit] = useForm(reference || '');
    const isInputError = (inputName: string) =>
        state?.errors.some((error) => error.field === inputName);

    const cases = useMemo(
        () => [
            {
                id: 1,
                value: dictionary?.contactFormValueQuote,
                label: dictionary?.contactFormLabelQuote,
            },
            {
                id: 2,
                value: dictionary?.contactFormValueOngoing,
                label: dictionary?.contactFormLabelOngoing,
            },
            {
                id: 3,
                value: dictionary?.contactFormValueQuestions,
                label: dictionary?.contactFormLabelQuestions,
            },
            {
                id: 4,
                value: dictionary?.contactFormValueClaims,
                label: dictionary?.contactFormLabelClaims,
            },
            {
                id: 5,
                value: dictionary?.contactFormValueOther,
                label: dictionary?.contactFormLabelOther,
            },
        ],
        [dictionary],
    );

    if (state.succeeded) {
        return (
            <>
                <h2 className="text-lg font-bold">
                    {dictionary?.formSuccessHeading}
                </h2>
                <p className="mt-4 text-sm">{dictionary?.formSuccessText}</p>
            </>
        );
    }

    return (
        <form onSubmit={handleSubmit} className="not-prose max-w-4xl space-y-3">
            <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                <div>
                    <Input
                        id="name"
                        name="Name"
                        type="text"
                        required
                        fullWidth
                        label={dictionary?.formNameInputLabel}
                        classNames={{
                            input: isInputError('Name')
                                ? 'border border-error'
                                : '',
                        }}
                    />
                    <ValidationError
                        className="mt-2 text-sm"
                        field="Name"
                        errors={state.errors}
                    />
                </div>
                <div>
                    <Input
                        id="email"
                        name="Email"
                        type="email"
                        required
                        fullWidth
                        label={dictionary?.formEmailInputLabelShort}
                        classNames={{
                            input: isInputError('Email')
                                ? 'border border-error'
                                : '',
                        }}
                    />
                    <ValidationError
                        className="mt-2 text-sm"
                        field="Email"
                        errors={state.errors}
                    />
                </div>
            </div>

            <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                <div>
                    <Input
                        id="phonenumber"
                        name="Phonenumber"
                        type="text"
                        required
                        fullWidth
                        label={dictionary?.contactFormPhoneInputLabel}
                        classNames={{
                            input: isInputError('Phonenumber')
                                ? 'border border-error'
                                : '',
                        }}
                    />
                    <ValidationError
                        className="mt-2 text-sm"
                        field="Phonenumber"
                        errors={state.errors}
                    />
                </div>
                <div>
                    <Input
                        id="orderNumber"
                        name="Order Number"
                        type="text"
                        fullWidth
                        label={dictionary?.contactFormOrderNumberLabel}
                        classNames={{
                            input: isInputError('Order Number')
                                ? 'border border-error'
                                : '',
                        }}
                    />
                    <ValidationError
                        className="mt-2 text-sm"
                        field="Order Number"
                        errors={state.errors}
                    />
                </div>
            </div>

            <Select
                name="case"
                placeholder={dictionary?.contactFormSelectPlaceholder}
                options={cases}
                required>
                <ValidationError
                    className="mt-2 text-sm"
                    field="case"
                    errors={state.errors}
                />
            </Select>

            <Textarea
                name="Message"
                id="message"
                label={dictionary?.contactFormMessageTextareaLabel}
                fullWidth
                resizable="none"
                classNames={{
                    textarea: isInputError('Message')
                        ? 'border border-error'
                        : '',
                }}
            />
            <ValidationError
                className="mt-2 text-sm"
                field="Message"
                errors={state.errors}
            />

            <div className="mt-6 grid grid-cols-1 gap-3 md:grid-cols-2">
                <Button
                    type="submit"
                    fullWidth
                    className="btn-primary"
                    isLoading={state.submitting}>
                    {dictionary?.contactFormActionButtonText}
                </Button>
            </div>

            {/* Honeypot */}
            <input type="text" name="_gotcha" className="hidden" />
        </form>
    );
}
